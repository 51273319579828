// poppins-regular
@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/poppins-v20-latin-regular.woff2') format('woff2');
}

// poppins-700
@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/poppins-v20-latin-700.woff2') format('woff2');
}
