html {
  @apply h-full;
}

body {
  @apply h-full bg-white text-black font-project;
}

.button {
  @apply inline-flex shadow-[0px_2px_0px_0px_rgb(16,9,107)] rounded-[5px] px-[72px] py-[7px] text-[16px] uppercase font-bold;

  &:hover {
    @apply no-underline;
  }
}

.link-hover {
  &:hover {
    @apply underline underline-offset-[0.15625em] decoration-[0.125rem] decoration-primary;
  }
}

.btn-primary {
  @apply flex justify-center border-2 border-black font-bold text-white hover:text-black no-underline hover:no-underline uppercase rounded-lg py-[13px] px-[24px] bg-black hover:bg-transparent;

  &.-invert {
    @apply bg-primary border-primary text-black hover:bg-transparent;
  }
}
